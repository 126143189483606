import './Loading.css'

const Loading = () => {
  return (
    <div className='w-full flex flex-col gap-4 justify-center items-center mt-16'>
        <div class="spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <h2 className='text-xl font-semibold font-poppins text-[#e8604c]'>Loading you tour...</h2>
    </div>
  )
}

export default Loading