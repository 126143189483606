import React, { useEffect } from 'react'
import Hero from './Hero'
import Destinations from './Destinations'
import Testimonial from './Testimonial'
import { Link } from 'react-router-dom'
import gsap from 'gsap'
import image4 from '../assets/images/image4.jpg'
import image1 from '../assets/images/image1.jpg'
import image14 from '../assets/images/image14.jpg'
import image17 from '../assets/images/image17.jpg'
import GetQuote from './GetQuote'

const LandingPage = () => {

  useEffect(() => {
    gsap.fromTo(".product-card", {
       x: "-100%", // Start from off-screen to the left
       opacity: 0,
    }, {
       duration: 1.5,
       x: "0%", // End at the original position
       opacity: 1,
       stagger: 0.2, // Add a stagger effect for a sequential slide-in
       ease: "power2.out",
       onComplete: () => {
         gsap.set(".product-card", { x: "0%", opacity: 1 }); // Ensure final state is set
       },
    });
 }, []);

  return (
    <div className="flex flex-col min-h-screen bg-white select-none">
      <Hero/>
      <Destinations/>
      <Link to={'/gallery'} className='my-8 product-card'>
        <div
          className='flex flex-col items-center space-x-2'
        >
          <h2 className="italianno-regular pl-8">Discover the Journey</h2>
          <h1 className='text-black text-3xl mb-4 font-bold leading-tight font-poppins text-center pl-0 md:pl-8'>Witness the Wonders with HappilyTrip</h1>
          {/*  */}
        </div>
        <div className='flex-1 flex p-4'>
          <div className='grid grid-cols-2 grid-flow-row-dense md:grid-cols-4 gap-2 bg-[#e8604c17]'>
            <span className='relative grid col-span-0'>
              <img
                alt=''
                src={image1}
              />
            </span>
            <span className='relative col-span-0'>
              <img
                alt=''
                src={image14}
              />
            </span>
            
            <span className='relative col-span-2'>
              <img
                alt=''
                src={image4}
                className=''
              />
            </span>
            <span className='relative md:-mt-64 col-span-2 hidden sm:block'>
              <img
                alt=''
                src={image17}
                className=''
              />
            </span>
          </div>
        </div>
      </Link>
      <div className='flex justify-center items-center'>
        <div className=" py-6 lg:py-12 product-card">
          <h2 className="italianno-regular pl-8 text-center">Learn about us</h2>
          <h1 className='text-black text-3xl mb-4 font-bold leading-tight pl-0 md:pl-8 font-poppins text-center'>Dare to Explore with HappilyTrip</h1>
          <div className="container px-4 md:px-6 mt-4 ">
            <div className="grid items-center gap-6 lg:grid-cols-2 lg:gap-12  p-2 rounded-xl shadow-lg bg-[#e8604c17]">
            
              <div className="grid items-center  md:pl-8 md:pt-8">
                <img
                  src="/HapplytripImage.jpg"
                  alt="HapplytripImage"
                  className="aspect-video overflow-hidden rounded-xl object-cover object-center w-auto md:max-w-96 lg:max-w-[500px]"
                />
              </div>
              <div className="space-y-4 text-left">
                <p className="md:max-w-[600px] w-auto text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed font-poppins">
                  We believe that every journey is an opportunity for adventure. HappilyTrip is dedicated to curating
                  unforgettable travel experiences that immerse our customers in the beauty, culture....
                </p>
              </div>
              <Link to={'/about'}>
                <button data-ripple-light="true" type="button" 
                  className="mb-2 select-none rounded-lg md:ml-8 bg-[#e8604c] py-3 px-6 text-center align-middle text-lg uppercase text-white shadow-md shadow-blue-500/20 transition-all hover:shadow-lg hover:shadow-blue-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none font-poppins">
                Know More
                </button>
              </Link>
            </div>
            
          </div>
        </div>
      </div>
      <GetQuote/>
      {/* <BookTour/> */}
      <Testimonial/>
    </div>
  )
}

export default LandingPage