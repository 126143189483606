import React, { useEffect } from 'react';
import { gsap } from 'gsap';

const Hero = () => {
 useEffect(() => {
    gsap.from(".hero-text", {
        duration: 1,
        opacity: 0,
        scale: 1.5, // Increase scale on larger screens
        y: -50,
        ease: "power1.out",
        delay: 0.5,
        onComplete: () => {
           gsap.to(".hero-text", {
             duration: 0.5,
             opacity: 1,
             ease: "power1.in"
           });
        }
       });
       
       // Adjust the scale for smaller screens
       gsap.from(".hero-text", {
        duration: 1,
        opacity: 0,
        scale: 1.2, // Reduced scale for smaller screens
        y: -50,
        ease: "power1.out",
        delay: 0.5,
        onComplete: () => {
           gsap.to(".hero-text", {
             duration: 0.5,
             opacity: 1,
             ease: "power1.in"
           });
        }
       }, {
        // Use the 'media' option to apply this animation only on screens smaller than 'md'
        media: "<=md"
       });

       gsap.from(".hero-button", {
        duration: 1,
        opacity: 0, // Start with full transparency
        scale: 1.5, // Start with a larger scale
        y: 50,
        ease: "power1.out",
        delay: 1,
        onComplete: () => {
          // Set opacity to 1 once the animation completes
          gsap.to(".hero-button", {
            duration: 0.5,
            opacity: 1,
            ease: "power1.in"
          });
        }
      });
       
 }, []);

 return (
  <section className="bg-cover bg-center h-auto md:h-[600px]  text-white overflow-hidden drop-shadow-lg w-full">
    <video autoPlay muted loop>
      <source src='./with.mp4' type="video/mp4" className='w-full h-full overflow-hidden'/>
    </video>

  </section>
 );
};

export default Hero;
