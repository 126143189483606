import React from 'react'
import image4 from '../assets/images/image4.jpg'
import image1 from '../assets/images/image1.jpg'
import image14 from '../assets/images/image14.jpg'
import image2 from '../assets/images/image2.jpg'
import image3 from '../assets/images/image3.jpg'
import image5 from '../assets/images/image5.jpg'
import image6 from '../assets/images/image6.jpg'
import image7 from '../assets/images/image7.jpg'
import image8 from '../assets/images/image8.jpg'
import image9 from '../assets/images/image9.jpg'
import image10 from '../assets/images/image10.jpg'

const Gallery = () => {
  return (
    <div className='flex'>
        <main className='flex-1'>
            <div className='grid grid-cols-1 grid-flow-row-dense md:grid-cols-4 gap-6 m-6'>
                <span className=' h-full md:h-32 max-sm:col-span-2'>
                    <img
                        alt=''
                        src={image4}
                        loading='lazy'
                    />
                </span>
                <span className=' col-span-2 row-span-2'>
                    <img
                        alt=''
                        src={image1}
                        loading='lazy'
                    />
                </span>
                <span className='col-span-2'>
                    <img
                        alt=''
                        src={image14}
                        loading='lazy'
                    />
                </span>
                <span className='h-64 w-full'>
                    <img
                        alt=''
                        src={image2}
                        loading='lazy'
                        className='w-full'
                    />
                </span>
                <span className='h-64 col-span-2'>
                    <img
                        alt=''
                        src={image3}
                        loading='lazy'
                    />
                </span>
                <span className='h-64 col-span-2 mt-4 md:mt-0'>
                    <img
                        alt=''
                        src={image5}
                        loading='lazy'
                    />
                </span>
                {/*  */}
                <span className=' h-full md:h-32 max-sm:col-span-2 mt-4 md:mt-0'>
                    <img
                        alt=''
                        src={image6}
                        loading='lazy'
                    />
                </span>
                <span className=' col-span-2 '>
                    <img
                        alt=''
                        src={image7}
                        loading='lazy'
                    />
                </span>
                <span className='col-span-2'>
                    <img
                        alt=''
                        src={image8}
                        loading='lazy'
                    />
                </span>
                <span className='h-64'>
                    <img
                        alt=''
                        src={image9}
                        loading='lazy'
                    />
                </span>
                <span className=' col-span-2'>
                    <img
                        alt=''
                        src={image10}
                        loading='lazy'
                    />
                </span>
                
            </div>
        </main>
    </div>
  )
}

export default Gallery