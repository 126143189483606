
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Destination } from '../utils/constants';
import 'tailwindcss/tailwind.css';
import GalleryCarousel from './GalleryCarousel';
import BookingTour from './BookingTour';
import Loading from './Loading';
import IncludeExclude from './IncludeExclude';

const ProductDetail = () => {
 const { name } = useParams();
 const [product, setProduct] = useState(null);
 const [openAccordion, setOpenAccordion] = useState(null);


 useEffect(() => {
   const fetchProduct = () => {
     const product = Destination.destinations.find(
       (destination) => destination.name === name
     );
     setProduct(product);
   };

   fetchProduct();
 }, [name]);

 if (!product) 
  return (
      <Loading/>
  )
 
 const handleAccordionClick = (index) => {
  setOpenAccordion(openAccordion === index ? null : index);
};

 return (
  <div className='container w-full mx-auto'>
    <GalleryCarousel/>
    <div className='bg-[#e8604c17] md:flex justify-around items-center'>
      <div className='p-4 border-b border-gray-500 md:border-none'>
        <p className='text-2xl font-semibold text-black pl-2 font-poppins'>{product.name}</p>
        <p className='text-gray-500 flex items-center text-base font-semibold gap-1 font-poppins'>
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#e8604c" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
            </svg>
          </span>
          {product.location}
        </p>
      </div>
      <div className='flex justify-evenly items-center'>
        <div className='flex gap-1 p-4'>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#e8604c" class="w-7 h-7">
            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z" />
          </svg>
          <p className='flex flex-col leading-snug text-base font-semibold pl-1 font-poppins'>
            From
            <span className='text-[#e8604c]'>₹{product.startingPrice}</span>
          </p>
        </div>
        <div className='flex gap-1 p-4'>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#e8604c" class="w-7 h-7">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
          <p className='flex flex-col leading-snug text-base font-semibold pl-1 font-poppins'>
            Duration
            <span className='text-[#e8604c]'>{product.duration}</span>
          </p>
        </div>
      </div>
    </div>
    {/* overview */}
    <div className='flex flex-col md:flex-row w-11/12 justify-center mx-auto'>
      <div className='w-auto md:w-[60%]'>
        <div className='p-4 md:p-16 font-poppins'>
          <h3 className='text-2xl font-semibold text-black'>Overview</h3>
          <p className='text-gray-600 text-lg'>{product.description}</p>
        </div>
        {/* Include/Exclude */}
        <div className='w-full justify-between'>
          <IncludeExclude include = {product.include} exclude={product.exclude} />
          {/* TourPlan */}
          <div className='p-4 md:p-16 w-auto md:w-[50%] font-poppins'>
            <h3 className='text-2xl font-semibold text-black'>Tour Plan</h3>
            <div>
              {
                product.trips.map((trip, tripIndex) => (
                  <div key={tripIndex}>
                    {trip.days.map((day, dayIndex) => (
                      <div key={dayIndex}
                      className='w-full p-2 md:w-96'
                      >
                        <button onClick={() => handleAccordionClick(dayIndex)} 
                          className="flex justify-between items-center w-full  p-2 rounded-md bg-[#e8604c17] font-semibold text-sm"
                        >
                          <p className='font-poppins text-lg p-1'>Day {day.day}</p>
                          <span>
                            {openAccordion === dayIndex ? 

                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                              </svg>

                              : 
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                              </svg>
                            }
                          </span>
                        </button>
                        {/* {openAccordion === dayIndex && (
                          <div className="accordion-content">
                            <p>{day.activities ? day.activities[0].description : 'No activities for this day'}</p>
                          </div>
                        )} */}
                        {openAccordion === dayIndex && (
                          <div className="p-2 bg-[#e8604c17]">
                            {day.departure && (
                              <div className='text-sm'>
                                <h3 className='items-center flex justify-center font-bold'>Departure</h3>
                                <p><span className='font-semibold'>From :</span> {day.departure.from}</p>
                                <p><span className='font-semibold'>Time :</span> {day.departure.time}</p>
                              </div>
                            )}
                            {day.pickup && (
                              <div className='text-sm'>
                                <h3 className='items-center flex justify-center font-bold'>Pickup</h3>
                                <p><span className='font-semibold'>Location :</span> {day.pickup.location}</p>
                                <p><span className='font-semibold'>Time :</span> {day.pickup.time}</p>
                              </div>
                            )}
                            {day.arrival && (
                              <div className='text-sm'>
                                <h3 className='items-center flex justify-center font-bold'>Arrival</h3>
                                <p><span className='font-semibold'>Location :</span> {day.arrival.location}</p>
                                <p><span className='font-semibold'>Time :</span> {day.arrival.time}</p>
                              </div>
                            )}
                            {day.activities && day.activities.map((activity, activityIndex) => (
                              <div key={activityIndex}
                              >
                                {/* <h3>Activity</h3> */}
                                <p className=' text-gray-500 flex items-center text-lg font-semibold gap-1'>
                                  <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.0" stroke="#e8604c" class="w-6 h-6">
                                      <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                      <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                                    </svg>
                                  </span> 
                                  {activity.location}
                                </p>
                                {/*  */}
                                {
                                  Array.isArray(activity.description) && (
                                    activity.description.map((description, index) => (
                                      <div className='flex gap-2 m-2'>
                                        <span>•</span>
                                        <p className='text-gray-500 flex items-center text-base gap-1'>{description}</p>
                                      </div>
                                    ))
                                  )
                                }
                              </div>
                            ))}
                            {day.return && (
                              <div>
                                <h3 className='items-center flex justify-center font-bold'>Return</h3>
                                <p><span className='font-semibold'>Location :</span> {day.return.location}</p>
                                <p><span className='font-semibold'>Time :</span> {day.return.time}</p>
                                <h3 className='items-center flex justify-center font-bold'>Message</h3>
                                <p className='font-poppins'>{day.return.message}</p>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
      <div className='w-auto md:w-[40%]'>
        <BookingTour pricingOptions={product.pricingOptions}/>
      </div>
    </div>
  </div>
 );
};

export default ProductDetail;
