
import React from 'react';
import { Link } from 'react-router-dom'; 

const ProductCard = ({ className, name, location,duration, description, images,startingPrice, totalTravelers }) => {
    
 return (
    <div className={`relative md:w-80 drop-shadow-xl ${className}`}>
        <div className='relative'>
            <img
                alt={name}
                src={images[0]}
                loading='lazy'
                className='w-full h-full rounded-t-lg object-cover'
            />
        </div>
        <div className='w-full relative bg-white border-2 border-gray-100 rounded-t-2xl rounded-b-lg pb-4 -mt-6 z-50 font-poppins'>
            <div className='p-2'>
                <h3 className='text-lg font-semibold text-black pl-2'>{name}</h3>
                <p className='text-gray-500 flex items-center text-sm font-semibold gap-1'> <span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#e8604c" class="w-4 h-4">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                    </svg>
                </span>{location}</p>
            </div>
            <p className='text-sm pl-4 font-semibold'>From  
                <span className='text-[#e8604c]'> ₹{startingPrice}</span>
            </p>
            <div className='bg-[#e8604c17] m-4 p-2 rounded-2xl text-sm font-semibold flex items-center justify-evenly px-4 text-gray-500'>
                <div className='flex gap-1'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#e8604c" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>
                    <span>{duration}</span>
                </div>
                <div className='flex gap-1'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#e8604c" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
                    </svg>
                    <span>{totalTravelers}</span>
                </div>
                <Link to={`/product/${name}`} className='text-[#e8604c] flex gap-1'>Explore
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                        </svg>
                    </span>
                </Link>
            </div>
        </div>
    </div>
 );
};

export default ProductCard;
