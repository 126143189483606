import React, { useState, useEffect } from 'react';
import 'tailwindcss/tailwind.css';
import { testimonials } from '../utils/constants';

const Testimonial = () => {
 const [current, setCurrent] = useState(0);

 useEffect(() => {
    const nextTestimonial = () => {
      setCurrent(current => (current + 1) % testimonials.length);
    };

    const timer = setInterval(nextTestimonial, 3000); // Change testimonial every 3 seconds

    return () => clearInterval(timer); // Clean up on component unmount
 }, [testimonials.length]);

 return (
    <div className="container mx-auto px-4 py-8 flex flex-col justify-center items-center">
      <h2 className="italianno-regular">Testimonials & reviews</h2>
      <h1 className='text-black text-3xl mb-4 font-bold leading-tight font-poppins'>What They're Saying</h1>
      <div className="bg-[#e8604c17] p-8 max-w-sm rounded-lg shadow-lg w-auto h-64">
        <div className="flex items-center space-x-4">
          <img src={testimonials[current].image} alt={testimonials[current].name} className="h-16 w-16 rounded-full " />
          <div>
            <div className="flex justify-center space-x-1 text-green-500">
              {/* Dynamically render stars based on the current testimonial's stars property */}
              {Array.from({ length: testimonials[current].stars }, (_, index) => (
                <svg key={index} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" className='w-6 h-6'>
                 <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                </svg>
              ))}
            </div>
            <p className="mt-1 text-lg font-semibold text-gray-800 font-poppins">{testimonials[current].name}</p>
          </div>
        </div>
        <p className="mt-4 text-gray-500 md:text-lg lg:text-base xl:text-xl overflow-hidden overflow-ellipsis font-poppins">
          {testimonials[current].testimonial}
        </p>
      </div>
    </div>
 );
};

export default Testimonial;
