import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/16.png'

const Navigation = () => {
  const[menuOpen, setMenuOpen] = useState(false)
  const toggleMunu = () => {
    setMenuOpen(!menuOpen);
  }

 return (
    <header className='bg-white  flex sticky top-0 h-20 z-30 justify-between items-center px-6 shadow-lg select-none w-full'>
      {/* Logo */}
      <Link to={"/"} className="font-medium text-xl">
        <img src={logo} alt="Logo"
          className='w-28 h-20 bg-center'
        />
      </Link>
      {/* nav */}
      <nav className="ml-auto gap-4 sm:gap-6 hidden md:flex">
          <Link className="text-sm font-medium md:text-lg font-poppins" to={"/"}>
            Home
          </Link>

          <Link className="text-sm font-medium md:text-lg font-poppins" to={"/features"}>
            Features
          </Link>

          <Link className="text-sm font-medium md:text-lg font-poppins" to={"/CancellationPolicy"}>
            Cancellation Policy
          </Link>

          <Link className="text-sm font-medium md:text-lg font-poppins" to={"/about"}>
            About
          </Link>
          <Link className="text-sm font-medium md:text-lg font-poppins" to={"/contact"}>
            Contact
          </Link>
      </nav>
      {/* Mobile menu button */}
      <nav className="md:hidden">
            <button
              className=''
              onClick={toggleMunu}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>

            </button>
            <div>
              {
                menuOpen && (
                  <div className="w-[60%] fixed flex flex-col top-0 right-0 h-screen  bg-white transform transition-transform duration-300 ease-out z-40 py-6 space-y-4 drop-shadow-lg">
                    <button 
                      onClick={toggleMunu}
                      className='m-4'
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                      </svg>
                    </button>
                    <nav className="flex flex-col space-y-4">
                      <Link 
                        className="font-medium text-lg border-b-2" 
                        to={"/"}
                        onClick={toggleMunu}
                      >
                        <span className='m-4 p-2 font-poppins'>Home</span>
                      </Link>
                      <Link 
                        onClick={toggleMunu}
                        className="font-medium text-lg border-b-2" 
                        to={"/features"}
                      >
                        <span className='m-4 p-2 font-poppins'>Features</span>
                      </Link>
                      <Link 
                        onClick={toggleMunu}
                        className="font-medium text-lg border-b-2" 
                        to={"/CancellationPolicy"}
                      >
                        <span className='m-4 p-2 font-poppins'>Cancellation Policy</span>
                      </Link>
                      <Link 
                        onClick={toggleMunu}
                        className="font-medium text-lg border-b-2" 
                        to={"/about"}
                      >
                        <span className='m-4 p-2 font-poppins'>About</span>
                      </Link>
                      <Link
                        onClick={toggleMunu} 
                        className="font-medium text-lg border-b-2" 
                        to={"/contact"}
                      >
                        <span className='m-4 p-2 font-poppins'>Contact</span>
                      </Link>
                    </nav>
                  </div>
                )
              }
            </div>
        </nav>
    </header>
 );
};

export default Navigation;
