import { useRef } from "react";
import emailjs from "@emailjs/browser";
import { Slide, toast } from "react-toastify";

// Replace these with your actual EmailJS IDs
// const SERVICE_ID = process.env.REACT_APP_SERVICE_ID
// const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID
// const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY

const GetQuote = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_1gnmm1k", "template_7c8tud6", form.current, {
        publicKey: "DC9keOq1Enn026Se3",
      })
      .then(
        () => {
          toast.success("Send Successfully", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
            style: { marginTop: '25px' },
          });
        },
        (error) => {
          toast.error('Failed !', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
            style: { marginTop: '25px' },
            });
        }
      );
  };

  // const handleClick = () => {
  //   toast.success("Email Successfully", {
  //     position: "top-center",
  //     autoClose: 5000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     theme: "light",
  //   });
  // };

  return (
    <div className="w-full flex justify-center items-center relative flex-col mt-4">
      <h2 className="italianno-regular text-center">Customize Trip</h2>

      <h1 className="text-black text-3xl mb-4 font-bold leading-tight font-poppins">
      Craft Your Journey
      </h1>
      <div className="p-4 m-4 mx-6 rounded-lg max-sm:w-80 sm:w-96 md:w-[600px] bg-[#e8604c17] shadow-lg relative">
        <form
          id="contact-form"
          className="space-y-4 my-4 mx-2 font-poppins relative"
          ref={form}
          onSubmit={sendEmail}
        >
          <div className="flex flex-col">
            <label
              className="text-lg font-semibold pl-1 leading-loose"
              htmlFor="name"
            >
              Name
            </label>
            <input
              type="text"
              name="form_name"
              placeholder="Name"
              required
              id="name"
              className="placeholder:w-auto p-2 bg-transparent border outline-none border-[#e8604c] rounded-lg "
            />
          </div>
          <div className="flex flex-col">
            <label
              className="text-lg font-semibold pl-1 leading-loose"
              htmlFor="email"
            >
              Email
            </label>
            <input
              type="email"
              name="form_email"
              placeholder="Enter Email"
              required
              id="email"
              className="placeholder:w-auto p-2 bg-transparent border outline-none border-[#e8604c] rounded-lg "
            />
          </div>
          <div className="flex flex-col">
            <label className="text-lg font-semibold pl-1 leading-loose">
              Phone
            </label>
            <input
              type="tel"
              name="form_tel"
              placeholder="Phone Number"
              required
              id="phone"
              className="placeholder:w-auto p-2 bg-transparent border outline-none border-[#e8604c] rounded-lg "
            />
          </div>
          <div className="flex flex-col">
            <label className="text-lg font-semibold pl-1 leading-loose">
              Number Of People
            </label>
            <input
              type="number"
              name="form_numberofppl"
              required
              placeholder="Enter number of people"
              id="numberOfPeople"
              min="1"
              max="20"
              className="placeholder:w-auto p-2 bg-transparent border outline-none border-[#e8604c] rounded-lg"
            />
          </div>
          <div className="flex flex-col">
            <label
              className="text-lg font-semibold pl-1 leading-loose"
              htmlFor="destination"
            >
              Destination
            </label>
            <select
              id="destination"
              name="form_tour"
              required
              className="bg-transparent border border-[#e8604c] p-2 rounded-lg text-lg font-poppins w-auto outline-none"
            >
              <option>Manali Tour</option>
              <option>Jaisalmer Tour</option>
              <option>Kedarnath Tour</option>
            </select>
          </div>
          <div className="flex flex-col">
            <label
              className="text-lg font-semibold pl-1 leading-loose"
              htmlFor="tripDay"
            >
              Choose Day
            </label>
            <input
              type="date"
              id="tripDay"
              name="form_date"
              placeholder="Select Day"
              required
              className="placeholder:w-auto p-2 text-lg bg-transparent border outline-none border-[#e8604c] rounded-lg"
            />
          </div>
          <button
            type="submit"
            // onClick={handleClick}
            className="flex mx-auto font-poppins text-lg bg-[#e8604c] text-white p-2 px-3 rounded-lg"
          >
            Get Quote
          </button>
        </form>
      </div>
    </div>
  );
};

export default GetQuote;
