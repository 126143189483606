import image17 from '../assets/images/image17.jpg'

const CancellationPolicy = () => {

  return (
    <div className="container pb-6">
      <div className='w-full h-full'>
        <img
          src={image17}
          alt=""
          className='w-full h-full md:h-[450px] object-cover object-bottom rounded shadow-lg'
        />
      </div>
      <div className='p-2 md:p-6 m-2 md:m-6'>
        <div className='flex items-center gap-x-2'>
          <span className='bg-[#e8604c] w-[3px] h-5'></span>
          <h1 className='text-2xl font-bold py-4 font-poppins'>Cancellation Policy</h1>
        </div>
        <div className='bg-[#e8604c17] p-4'>
          <h2 className='text-xl font-semibold flex gap-x-2 leading-9 font-poppins'>
            <span>1.</span>
            <p >15 Days Before Trip Start</p>
          </h2>
          <p className='text-lg font-semibold text-gray-500 font-poppins'>If cancellations are made 15 days before the trip's start date, a cancellation fee of 50% of the total package cost will be charged.</p>
        </div>

        <div className='bg-white p-4'>
          <h2 className='text-xl font-semibold flex gap-x-2 leading-9 font-poppins'>
            <span>2.</span>
            <p >7-15 Days Before Trip Start</p>
          </h2>
          <p className='text-lg font-semibold text-gray-500 font-poppins'>Cancellations made within 15-7 days before the trip will incur a charge of 75% of the total package cost.</p>
        </div>

        <div className='bg-[#e8604c17] p-4'>
          <h2 className='text-xl font-semibold flex gap-x-2 leading-9 font-poppins'>
            <span>3.</span>
            <p >Within 0-7 Days Before Trip Start</p>
          </h2>
          <p className='text-lg font-semibold text-gray-500 font-poppins'>For cancellations made within 0-7 days before the trip's start date, 100% of the trip cost will be charged.</p>
        </div>

        <div className='bg-white p-4'>
          <h2 className='text-xl font-semibold flex gap-x-2 leading-9 font-poppins'>
            <span>4.</span>
            <p >Non-Refundable Advance</p>
          </h2>
          <p className='text-lg font-semibold text-gray-500 font-poppins'>Please note that the advance amount is non-refundable under any condition.</p>
        </div>

        <div className='bg-[#e8604c17] p-4'>
          <h2 className='text-xl font-semibold flex gap-x-2 leading-9 font-poppins'>
            <span>5.</span>
            <p >Flexible Travel Dates</p>
          </h2>
          <p className='text-lg font-semibold text-gray-500 font-poppins'>You have the flexibility to extend your travel date before 20-10 days with no additional charges. We understand plans can change, and we strive to accommodate your needs to the best of our ability.</p>
        </div>
      </div>

      <div className='p-2 md:p-6 m-2 md:m-6'>
        <div className='flex items-center gap-x-2'>
          <span className='bg-[#e8604c] w-[3px] h-5'></span>
          <h1 className='text-2xl font-bold py-4 font-poppins'>Important Guidelines</h1>
        </div>
        <div className='bg-[#e8604c17] p-4'>
          <h2 className='text-xl font-semibold flex gap-x-2 leading-9 font-poppins'>
            <span>1.</span>
            <p>Respect Female Co-Passengers</p>
          </h2>
          <p className='text-lg font-semibold text-gray-500 font-poppins'> We uphold a strict policy of respect towards female co-passengers. Any misbehavior will lead to the immediate removal of the entire group from the trip. Those responsible will have to arrange their own travel and accommodation at their own expense, and they will no longer be part of this trip. We will not be liable for any expenses incurred.</p>
        </div>

        <div className='bg-white p-4'>
          <h2 className='text-xl font-semibold flex gap-x-2 leading-9 font-poppins'>
            <span>2.</span>
            <p>Transport Breakdown</p>
          </h2>
          <p className='text-lg font-semibold text-gray-500 font-poppins'>If you have a breakdown, please be patient. We appreciate your understanding as we work to repair the transport. Delays caused by breakdowns are beyond our control.</p>
        </div>

        <div className='bg-[#e8604c17] p-4'>
          <h2 className='text-xl font-semibold flex gap-x-2 leading-9 font-poppins'>
            <span>3.</span>
            <p>Delays and Program Alterations</p>
          </h2>
          <p className='text-lg font-semibold text-gray-500 font-poppins'>We are not responsible for delays or changes in the program due to factors such as traffic, road construction, or bad weather. Your understanding in such situations is greatly appreciated.</p>
        </div>

        <div className='bg-white p-4'>
          <h2 className='text-xl font-semibold flex gap-x-2 leading-9 font-poppins'>
            <span>4.</span>
            <p>Payment and Cancellation Policy</p>
          </h2>
          <p className='text-lg font-semibold text-gray-500 font-poppins'>Full payment of the trip cost must be made upon boarding the traveler. Pending payments may result in trip cancellation without a refund of the booking amount.</p>
        </div>

        <div className='bg-[#e8604c17] p-4'>
          <h2 className='text-xl font-semibold flex gap-x-2 leading-9 font-poppins'>
            <span>5.</span>
            <p>Seating Arrangements</p>
          </h2>
          <p className='text-lg font-semibold text-gray-500 font-poppins'>Seating arrangements for the traveler are at our discretion. We strive to accommodate couples together. Failure to comply with our seating r﻿equests may result in the cancellation of the trip for the individual without any refund.</p>
        </div>
      </div>
    </div>
  )
}

export default CancellationPolicy

