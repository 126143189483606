import { Link } from "react-router-dom";
import OpenWhatsAppChat from "./OpenWhatsAppChat";


const Footer = () => {

   // Function to handle email sending
  const sendEmail = () => {
    const email = "contact@happilytrip.com";
    const subject = encodeURIComponent("Subscribing to the HappilyTrip"); // Replace "Your Subject Here" with your actual subject
    const body = encodeURIComponent("Hi HappilyTrip"); // Replace "Your Email Body Here" with your actual email body
    window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
  };

 return (
    <footer className="bg-[#e8604c17] text-black p-4 w-full">
      <div className="container mx-auto">
        <div className="flex flex-wrap justify-between">
          <div className="w-full md:w-1/4 mb-4 md:mb-0 space-y-2">
            <h3 className="text-lg font-semibold mb-2 font-poppins">Contact Us</h3>
            <div className="font-poppins flex gap-2 items-center"><span>
              <img src="./mail.svg"
                className="w-5 h-5"
              />
            </span> contact@happilytrip.com</div>
            <div className="font-poppins flex gap-2 items-center"><span>
            <img src="./phone.svg"
                className="w-5 h-5"
              />
            </span> +91 9034943903</div>
            <OpenWhatsAppChat/>
          </div>
          <div className="w-full md:w-1/4 mb-4 md:mb-0">
            <h3 className="text-lg font-semibold mb-2 font-poppins">Follow Us</h3>
            <div className="flex space-x-4">
              <a href="https://www.instagram.com/happilytrip.in/?igsh=MWxyNWRnNHRkdjRpMA%3D%3D" className="w-8 h-8">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#fb0364" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>
              </a>
            </div>
          </div>
          <div className="w-full md:w-1/4 mb-4 md:mb-0">
            <h3 className="text-lg font-semibold mb-2 font-poppins">Quick Links</h3>
            <ul className="list-none">
              <li><Link to={"/"} className="text-[#e8604c] font-poppins">Home</Link></li>
              <li><Link to={'/about'} className="text-[#e8604c] font-poppins">About Us</Link></li>
              <li><Link to={'/contact'} className="text-[#e8604c] font-poppins">Contact</Link></li>
            </ul>
          </div>
          <div className="w-full md:w-1/4">
            <h3 className="text-lg font-semibold mb-2 font-poppins">Newsletter</h3>
            <p className="font-poppins">Subscribe to our newsletter to receive updates and offers.</p>
            <form className="flex" onSubmit={(e) => {
              e.preventDefault();
              sendEmail();
            }}>
              <input type="email" placeholder="Your Email" className="flex-grow rounded border text-black outline-none border-gray-300 px-3 py-2 mr-2 placeholder:font-poppins" />
              <button type="submit" className="bg-emerald-400/90 hover:bg-emerald-400/70 text-white font-bold py-1 px-2 rounded font-poppins">Subscribe</button>
            </form>
          </div>
        </div>
        <div className="text-center mt-4">
          <p className="font-poppins">&copy; 2024 HappilyTrip. All rights reserved.</p>
        </div>
      </div>
    </footer>
 );
};

export default Footer;
