export const Destination = {
  destinations: [
    {
      name: "Manali Tour",
      location: "Manali, Himachal Pradesh",
      description:
        "Manali is a popular hill station situated in the northern state of Himachal Pradesh. It is surrounded by snow-capped mountains, lush green forests, and serene valleys, making it an ideal destination for nature lovers and adventure enthusiasts. Manali is also known for its rich cultural heritage and offers a glimpse into the traditional life of the people living in the mountains",
      duration: "6 Days",
      startingPrice: " 4,999",
      totalTravelers: 50,
      trips: [
        {
          name: "Manali Adventure",
          days: [
            {
              day: 1,
              departure: {
                from: "Delhi",
                time: "6:30 p.m",
              },
              pickup: {
                location: "Chandigarh",
                time: "12:30 - 2:00 a.m",
              },
              arrival: {
                location: "Manali",
                time: "12:00 p.m",
              },
            },
            {
              day: 2,
              activities: [
                {
                  location: "Manali",
                  description:[
                    "Arrive in Manali between 9 to 12 pm and settle into your cozy hotel.", 
                    "Take a quick power nap for 1-2 hours to recharge.", 
                    "Start your adventure at the Hadimba Temple, followed by a leisurely walk at Van Vihar and a visit to the Monastery for tranquility.",
                     "Head to the Club House for adventure and explore Vasisth Temple and Jogni Waterfall for scenic beauty.", 
                     "End the day with a dinner and rest for tomorrow's adventures.",
                  ]
                },
              ],
            },
            {
              day: 3,
              activities: [
                {
                  location: "Solang Valley & Atal Tunnel",
                  description:[
                    "Wake up to stunning views and enjoy breakfast before heading to Solang Valley or the Atal Tunnel for thrilling snow activities like ziplining and skiing (at your own cost).", 
                    "Return to your hotel in the evening for dinner and rest, ready for more adventures in Manali.",
                  ]
                },
              ],
            },
            {
              day: 4,
              activities: [
                {
                  location: "Kullu - Kasol",
                  description:[
                    "Rise and shine to a delicious breakfast before checking out from your hotel.", 
                    "Head to Kullu for thrilling paragliding and rafting (at your own cost)." ,
                    "Later, reach the charming campsite in Kasol, surrounded by nature." ,
                    "Enjoy a lively evening with DJ music and a bonfire (weather permitting).", 
                    "Relish a tasty local dinner and unwind in Swiss-style camps.", 
                    "Get ready for another day of adventure in the serene beauty of Kasol and Kullu.",
                  ]
                },
              ],
            },
            {
              day: 5,
              activities: [
                {
                  location: "Market & Manikaran Gurudwara",
                  description:[
                    "Wake up to nature's beauty, have breakfast, and then leave the campsite.", 
                    "Explore Kasol Mall Road and the serene Manikaran Gurudwara in the Parvati Valley.", 
                    "Experience the rejuvenating Hot Water Springs and the Sikh culture at the Gurudwara's Langar.", 
                    "Depart from Kasol around 4 to 5 pm, carrying warm memories with you.",
                  ]
                },
              ],
            },
            {
              day: 6,
              return: {
                location: "Delhi",
                message:
                  "Return to Delhi, your journey's end, with a heart brimming with memories and a backpack full of experiences. The mountains may be behind you, but the moments you've collected will stay with you. Until the next adventure, Delhi greets you back with open arms. Happy travels with HAPPILY TRIP",
              },
            },
          ],
        },
      ],
      images: [
        "https://encrypted-tbn2.gstatic.com/licensed-image?q=tbn:ANd9GcRv-Tl3mO8LAUFtYSs2cpJ8l2h74TLVq__HE3P-j3jFHkLzkUWUAxrtN8_Ux_QQAdQz8u0xDYqfW6_vydoijym-LThDeoc9oi6drpqw9Q",
        "https://www.instagram.com/p/C21GjtvhRm9/",
        "https://via.placeholder.com/800x600",
      ],
      includeExclude: [],
      highlights: [],
      travelTips: [],
      pricingOptions: [
        {
          type: "Quad sharing",
          pricePerPerson: 5499,
          personsPerRoom: 4,
          additionalCharges: [
            {
              description: "Rohtang Pass",
              amount: 1000,
            },
            {
              description: "Booking Amount",
              amount: 1500,
            },
          ],
        },
        {
          type: "Triple sharing",
          pricePerPerson: 5999,
          personsPerRoom: 3,
          additionalCharges: [
            {
              description: "Rohtang Pass",
              amount: 1000,
            },
            {
              description: "Booking Amount",
              amount: 1500,
            },
          ],
        },
        {
          type: "Double sharing",
          pricePerPerson: 6499,
          personsPerRoom: 2,
          additionalCharges: [
            {
              description: "Rohtang Pass",
              amount: 1000,
            },
            {
              description: "Booking Amount",
              amount: 1500,
            },
          ],
        },
      ],
    },
    {
      name: "Jaisalmer Tour",
      location: "City in Rajasthan",
      description: `Jaisalmer is a former medieval trading center and a
        princely state in the western Indian state of
        Rajasthan, in the heart of the Thar Desert. Known
        as the "Golden City", it's distinguished by its
        yellow sandstone architecture. Dominating the
        skyline is Jaisalmer Fort, a sprawling hilltop citadel
        buttressed by 99 bastions. Behind its massive walls
        stand the ornate Maharaja's Palace and intricately
        carved Jain temples. What is Jaisalmer famous for?
        Jaisalmer is known for the majestic Jaisalmer fort
        that fences the city. The most amazing thing about
        this fort is that it is a living urban center. The walls
        of this fort house approximately 3000 residents of
        Jaisalmer. It is lined with houses and temples and
        woven beautifully in narrow winding lanes.`,
      duration: "5 Days",
      startingPrice: " 7,499",
      totalTravelers: 50,
      trips: [
        {
          name: "Jaisalmer",
          days: [
            {
              day: 1,
              departure: {
                from: "Delhi ",
                time: null,
              },
              pickup: "Gurgaon",
              arrival: null,
            },
            {
              day: 2,
              activities: [
                {
                  location:
                    "Sam Sand Dunes",
                  description:[
                    "Reach Sam in morning/afternoon, Check into Camps, freshen up and have lunch.",
                    "Enjoy Jeep safari and camel safari.",
                    "Return back to camps at 6:45 pm. Enjoy Traditional welcome with arti tika and dhol.",
                    "Freshen up, enjoy Culture program, Folk dance, Dj night and Bonfire.",
                    "Dinner overnight stay in camps."
                  ]
                },
              ],
            },
            {
              day: 3,
              activities: [
                {
                  location: "Longewala Tonot Temple",
                  description:[
                    "Wake up in morning, freshen up, have breakfast, later depart for Longewala war memorial mesume.",
                    "Experience meseum for around 1 hour, Do some lunch in Longewala at your own cost.",
                    "Later we'll visit Tanot Rai temple where 15 minutes will be given to do darshan and experience it.",
                    "Return back to camps. Enjoy dj night and bonfire.",
                    "Enjoy the Cultural program.",
                    "Dinner overnight stay in camps",
                  ]
                },
              ],
            },
            {
              day: 4,
              activities: [
                {
                  location: "Kuldhara | Bada Bagh",
                  description:[
                    "Wake up in morning freshen up have breakfast check out from Camps.",
                    "Later visit Kuldhara village and subsequently visit Bada bagh.",
                    "Later depart for Jaisalmer city. Experience Jaisalmer fort there for around 2 hours.",
                    "Later visit patwano ki haveli, there we will spend around 40 minutes.",
                    "Subsequently, we will head to Gadisar Lake, where we'll have a delightful 1.5-hour experience. Do boating here at own cost. Also Enjoy watershow.",
                    "Later depart for Delhi at 6:30 Pm sharp.",
                  ]
                },
              ],
            },
            {
              day: 5,
              return: {
                location: "Delhi",
                time: null,
                message:
                  "Return to Delhi, your journey's end, with a heart brimming with memories and a backpack full of experiences. The mountains may be behind you, but the moments you've collected will stay with you. Until the next adventure, Delhi greets you back with open arms. Happy travels with HAPPILY TRIP",
              },
            },
          ],
        },
      ],
      images: [
        "https://media.istockphoto.com/id/465538873/photo/scenic-jaisalmer-fort-with-a-sunset.jpg?b=1&s=612x612&w=0&k=20&c=DZ-sbNc15_DVhIFwdFqEJ7L8GfK_WORAkfuoxWhOojU=",
      ],
      include: [
        "AC Transport",
        "Luxury camp stay",
        "Meal(2 breakfast, 1, lunch and 2 dinner)",
        "Traditional welcome",
        "Camel safari",
        "Jeep safari",
        "Dj night and Bonfire",
        "Folk dance",
        "Tour guide",
        "Cultural program",
        "Tour cordinater",
        "All taxes and tolls",
        "Sightseeing"
      ],
      exclude: [
        "All entry tickets not include",
        "All personal Expenses",
        "Adventure activities not include",
        "All things not mentioned in itinerary",
      ],
      highlights: [],
      travelTips: [],
      pricingOptions: [
        {
          type: "Quad sharing",
          pricePerPerson: `5,799`,
          personsPerRoom: 4,
          additionalCharges: [
            {
              description: "Rohtang Pass",
              amount: 1000,
            },
            {
              description: "Booking Amount",
              amount: 1500,
            },
          ],
        },
        {
          type: "Triple sharing",
          pricePerPerson: `6,299`,
          personsPerRoom: 3,
          additionalCharges: [
            {
              description: "Rohtang Pass",
              amount: 1000,
            },
            {
              description: "Booking Amount",
              amount: 1500,
            },
          ],
        },
        {
          type: "Double sharing",
          pricePerPerson: `6,799`,
          personsPerRoom: 2,
          additionalCharges: [
            {
              description: "Rohtang Pass",
              amount: 1000,
            },
            {
              description: "Booking Amount",
              amount: 1500,
            },
          ],
        },
      ],
    },
    {
      name: "Kedarnath Tour",
      location: "Rudraprayag, Rudraprayag",
      description:
        "Kedarnath is a town and Nagar Panchayat in Rudraprayag district of Uttarakhand, India, known primarily for the Kedarnath Temple. It is approximately 86 kilometres from Rudraprayag, the district headquarter. Kedarnath is the most remote of the four Chota Char Dham pilgrimage sites.",
      duration: "5 Days",
      startingPrice: " 10,999",
      totalTravelers: 50,
      trips: [
        {
          name: "Kedarnath",
          days: [
            {
              day: 0,
              departure: {
                from: "Delhi To Sitapur/Sonprayag",
                time: null,
              },
              pickup: null,
              arrival: null,
            },
            {
              day: 1,
              activities: [
                {
                  location:
                    "Devprayag Dhari Devi To Sonprayag/Sitapur/Guptkashi",
                  description:[
                    "On the way visit Devprayag and Dhari Devi Temple, reach hotel by evening.", 
                    "Dinner and overnight stay at hotel.",
                  ]
                },
              ],
            },
            {
              day: 2,
              activities: [
                {
                  location: "Guptkashi/Sonprayag To Gaurikund To Kedarnath",
                  description:[
                    "Early morning checkout from Hotel & start Kedarnath yatra.",
                    "Take sharing jeep from Sonprayag to gaurikund.Start trek from Gaurikund Reach kedarnath by evening.",
                    "Dinner & overnight stay at hotel.",
                  ]
                },
              ],
            },
            {
              day: 3,
              activities: [
                {
                  location: "KEDARNATH JI DARSHAN & RETURN TO SONPRAYAG.",
                  description:[
                    "In morning Do darshan of holy shrine, then Bhairon temple.",
                    "Return to your stay and checkout.",
                    "Return trek to SonprayagDinner & overnight stay at hotel.",
                  ]
                },
              ],
            },
            {
              day: 4,
              activities: [
                {
                  location: "RETURN TO HARIDWAR /RISHIKESH",
                  description:[
                     "After breakfast checkout from Hotel.",

                    "Start your return journey to Haridwwar/Rishikesh/Delhi", 
                    "Reach your respective point by evening haridwar/Rishikesh. Later depart to delhi",
                  ]
                },
              ],
            },
            {
              day: 5,
              return: {
                location: "Delhi",
                time: null,
                message:
                  "Return to Delhi, your journey's end, with a heart brimming with memories and a backpack full of experiences. The mountains may be behind you, but the moments you've collected will stay with you. Until the next adventure, Delhi greets you back with open arms. Happy travels with HAPPILY TRIP",
              },
            },
          ],
        },
      ],
      images: [
        "https://encrypted-tbn3.gstatic.com/licensed-image?q=tbn:ANd9GcQQd0x3s6-GlN2UHeDRQNbDL3AH5ohF_u6bhMXosH8GXMku55ravHfECB_5SBcSOQPs1A6plwKc9XOwfoBUJRHIxejshgrVASiEh40uqg",
      ],
      include: [
        "AC Transport",
        "Luxury camp stay",
        "Meal(2 breakfast, 1, lunch and 2 dinner)",
      ],
      exclude: [
        "All entry tickets not include",
        "All personal Expenses",
        "Adventure activities not include",
        "All things not mentioned in itinerary",
      ],
      highlights: [],
      travelTips: [],
      pricingOptions: [],
      // pricingOptions: [
      //   {
      //     type: "Quad sharing",
      //     pricePerPerson: 5499,
      //     personsPerRoom: 4,
      //     additionalCharges: [
      //       {
      //         description: "Rohtang Pass",
      //         amount: 1000,
      //       },
      //       {
      //         description: "Booking Amount",
      //         amount: 1500,
      //       },
      //     ],
      //   },
      //   {
      //     type: "Triple sharing",
      //     pricePerPerson: 5999,
      //     personsPerRoom: 3,
      //     additionalCharges: [
      //       {
      //         description: "Rohtang Pass",
      //         amount: 1000,
      //       },
      //       {
      //         description: "Booking Amount",
      //         amount: 1500,
      //       },
      //     ],
      //   },
      //   {
      //     type: "Double sharing",
      //     pricePerPerson: 6499,
      //     personsPerRoom: 2,
      //     additionalCharges: [
      //       {
      //         description: "Rohtang Pass",
      //         amount: 1000,
      //       },
      //       {
      //         description: "Booking Amount",
      //         amount: 1500,
      //       },
      //     ],
      //   },
      // ],
    },
  ],
};

export const testimonials = [
  {
    name: "Ravi Yadav",
    title: "NaN",
    testimonial:
      "The service was excellent. The team was very professional and delivered on time. Highly recommended!",
    image: "https://via.placeholder.com/150",
    stars: 5,
  },
  {
    name: "Deepak Kumar",
    title: "NaN",
    testimonial:
      "I've used their services for several projects and I'm always impressed by their quality and reliability. They're a great partner.",
    image: "https://via.placeholder.com/150",
    stars: 4,
  },
  {
    name: "Smriti",
    title: "NaN",
    testimonial:
      "Their team is very responsive and they always go above and beyond to ensure our needs are met. I'll definitely be using them again.",
    image: "https://via.placeholder.com/150",
    stars: 3,
  },
  {
    name: "Saurabh Singh",
    title: "NaN",
    testimonial:
      "Their work is always top-notch. They understand our requirements and deliver solutions that exceed our expectations. Excellent service!",
    image: "https://via.placeholder.com/150",
    stars: 5,
  },
];
