import React, { useState } from 'react'
import OpenWhatsAppChat from './OpenWhatsAppChat';

const BookingTour = ({pricingOptions}) => {
    const [showPopup, setShowPopup] = useState(false);

    const handlePopUp = () => {
        setShowPopup(true)
    }
  
      const BookNowPopUp = ({ isOpen, onClose }) => {
          if (!isOpen) return null;
          return(
              <div className='fixed inset-0 flex items-center justify-center z-50'>
                  <div className='bg-white p-6 rounded-lg shadow-md'>
                      <button 
                          onClick={onClose}
                      className='flex justify-end items-center w-full mb-4'
                      >
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                          </svg>
                      </button>
                      <div>
                          <h2 className="text-xl font-semibold font-poppins">Make call now</h2>
                          <div className='flex justify-evenly mt-4 items-center'>
                              {/* call */}
                              <a href="tel:+919034943903">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='w-6 h-6'>
                                      <path fill="#e8604c" d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/>
                                  </svg>
                              </a>
                  
                              {/* whatsapp */}
                              <OpenWhatsAppChat/>
                          </div>
                      </div>
                  </div>
              </div>
          )
      }
  
  
    return (
      <div className='bg-[#e8604c17] w-full my-12 px-4 rounded-md h-auto overflow-hidden'>
        <div className='flex items-center p-2 space-x-2 border-b-2'>
          <span className='bg-[#e8604c] w-[3px] h-5'></span>
          <h3 className='font-semibold text-xl font-poppins'>Booking Information</h3>
        </div>
        {/* Tours Information */}
        <div>
          {
            pricingOptions.map((price, index) =>(
              <div className='p-2 w-full'>
                <div className='w-full border-b-2'>
                  <h3 className='font-semibold flex justify-center items-center text-lg font-poppins'>{price.type}</h3>
                  <div className='flex justify-center items-center p-2 text-gray-500 font-semibold'>
                    <p className='font-poppins'>{price.pricePerPerson}/- per person,</p>
                    <p> {price.personsPerRoom} per  room.</p>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
        <button 
          className='bg-[#e8604c] text-white font-semibold p-2 rounded-lg m-2 justify-center flex items-center w-full font-poppins'
          onClick={handlePopUp}
        >Book Now</button>
        <BookNowPopUp isOpen={showPopup} onClose={() => setShowPopup(false)}/>
      </div>
    )
  
}

export default BookingTour