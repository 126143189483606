import React from 'react'

const Features = () => {
  return (
    <div class="flex flex-col min-h-[100dvh]">
    <main class="flex-1">
      <section class="w-full py-6 md:py-12 lg:py-24 xl:py-32 bg-[#e8604c17]">
        <div class="container flex flex-col items-center justify-center space-y-4 px-4 md:px-6">
          <div class="space-y-2 text-center">
            <h1 class="text-4xl font-bold tracking-tighter lg:text-6xl/none font-poppins">Personalized Services</h1>
            <p class="max-w-[600px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400 font-poppins">
            We pride ourselves on providing personalized service to each of our clients. We take the time to understand your travel preferences, budget, and interests to create a customized itinerary that meets your specific needs.
            </p>
          </div>
          <div class="mx-auto w-full max-w-sm space-y-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="mx-auto h-32 w-32"
            >
              <path d="M6 20h0a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h0"></path>
              <path d="M8 18V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v14"></path>
              <path d="M10 20h4"></path>
              <circle cx="16" cy="20" r="2"></circle>
              <circle cx="8" cy="20" r="2"></circle>
            </svg>
          </div>
        </div>
      </section>
      <section class="w-full py-6 md:py-12 lg:py-24">
        <div class="container flex flex-col items-center justify-center space-y-4 px-4 md:px-6">
          <div class="space-y-2 text-center">
            <h1 class="text-4xl font-bold tracking-tighter lg:text-6xl/none font-poppins">Expertise</h1>
            <p class="max-w-[600px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400 font-poppins">
            Our team of travel experts has years of experience in the travel industry, and we have a deep knowledge of the destinations we offer. We stay up-to-date on the latest travel trends and can provide insider tips and recommendations to ensure that you have the best possible experience.
            </p>
          </div>
          <div class="mx-auto w-full max-w-sm space-y-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="mx-auto h-32 w-32"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <polygon points="16.24 7.76 14.12 14.12 7.76 16.24 9.88 9.88 16.24 7.76"></polygon>
            </svg>
          </div>
        </div>
      </section>
      <section class="w-full py-6 md:py-12 lg:py-24 bg-[#e8604c17]">
        <div class="container flex flex-col items-center justify-center space-y-4 px-4 md:px-6">
          <div class="space-y-2 text-center">
            <h1 class="text-4xl font-bold tracking-tighter lg:text-6xl/none font-poppins">Competitive Prices</h1>
            <p class="max-w-[600px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400 font-poppins">
            We offer competitive prices on all of our travel packages and services, without compromising on quality. We work with a network of trusted suppliers to provide you with the best value for your money.
            </p>
          </div>
          <div class="mx-auto w-full max-w-sm space-y-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="mx-auto h-32 w-32"
            >
              <path d="M12 2H2v10l9.29 9.29c.94.94 2.48.94 3.42 0l6.58-6.58c.94-.94.94-2.48 0-3.42L12 2Z"></path>
              <path d="M7 7h.01"></path>
            </svg>
          </div>
        </div>
      </section>
      <section class="w-full py-6 md:py-12 lg:py-24">
        <div class="container flex flex-col items-center justify-center space-y-4 px-4 md:px-6">
          <div class="space-y-2 text-center">
            <h1 class="text-4xl font-bold tracking-tighter lg:text-6xl/none font-poppins">Flexibility</h1>
            <p class="max-w-[600px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400 font-poppins">
              We understand that travel plans can change, which is why we offer flexible booking and cancellation policies. We are always available to help you make changes to your itinerary or to assist you with any unexpected issues that may arise during your trip.
            </p>
          </div>
          <div class="mx-auto w-full max-w-sm space-y-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="mx-auto h-32 w-32"
            >
              <path d="M21 7.5V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h3.5"></path>
              <path d="M16 2v4"></path>
              <path d="M8 2v4"></path>
              <path d="M3 10h5"></path>
              <path d="M17.5 17.5 16 16.25V14"></path>
              <path d="M22 16a6 6 0 1 1-12 0 6 6 0 0 1 12 0Z"></path>
            </svg>
          </div>
        </div>
      </section>
      <section class="w-full py-6 md:py-12 lg:py-24 bg-[#e8604c17]">
        <div class="container flex flex-col items-center justify-center space-y-4 px-4 md:px-6">
          <div class="space-y-2 text-center">
            <h1 class="text-4xl font-bold tracking-tighter lg:text-6xl/none font-poppins">Customer Support</h1>
            <p class="max-w-[600px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400 font-poppins">
              We provide 24/7 customer support to ensure that you have a seamless travel experience. Our team is available to answer any questions or concerns you may have, and we are committed to providing you with the highest level of customer service.
            </p>
          </div>
          <div class="mx-auto w-full max-w-sm space-y-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="mx-auto h-32 w-32"
            >
              <path d="M3 14h3a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-7a9 9 0 0 1 18 0v7a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3"></path>
            </svg>
          </div>
        </div>
      </section>
      <section class="w-full py-6 md:py-12 lg:py-24">
        <div class="container flex flex-col items-center justify-center space-y-4 px-4 md:px-6">
          <div class="space-y-2 text-center">
            <h1 class="text-4xl font-bold tracking-tighter lg:text-6xl/none font-poppins">Sustainable Tourism</h1>
            <p class="max-w-[600px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400 font-poppins">
            We are committed to promoting sustainable tourism practices and supporting local communities. We work with suppliers who share our values and are dedicated to minimizing the environmental impact of tourism.
            </p>
          </div>
          <div class="mx-auto w-full max-w-sm space-y-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="mx-auto h-32 w-32"
            >
              <path d="M11 20A7 7 0 0 1 9.8 6.1C15.5 5 17 4.48 19 2c1 2 2 4.18 2 8 0 5.5-4.78 10-10 10Z"></path>
              <path d="M2 21c0-3 1.85-5.36 5.08-6C9.5 14.52 12 13 13 12"></path>
            </svg>
          </div>
        </div>
      </section>
      <section class="w-full py-6 md:py-12 lg:py-24 bg-[#e8604c17]">
        <div class="container flex flex-col items-center justify-center space-y-4 px-4 md:px-6">
          <div class="space-y-2 text-center">
            <h1 class="text-4xl font-bold tracking-tighter lg:text-6xl/none font-poppins">Trust and Reputation</h1>
            <p class="max-w-[600px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400 font-poppins">
            We have a strong reputation in the travel industry, with many satisfied customers who have chosen us for their travel needs. We are committed to building trust with our clients and ensuring that they have a positive experience with our company.
            </p>
          </div>
          <div class="mx-auto w-full max-w-sm space-y-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="mx-auto h-32 w-32"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10"></path>
              <path d="m9 12 2 2 4-4"></path>
            </svg>
          </div>
        </div>
      </section>
      <section class="w-full py-6 md:py-12 lg:py-24">
        <div class="container flex flex-col items-center justify-center space-y-4 px-4 md:px-6">
          <div class="space-y-2 text-center">
            <h1 class="text-4xl font-bold tracking-tighter lg:text-6xl/none font-poppins">Customer Reviews</h1>
            <p class="max-w-[600px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400 font-poppins">
              Hear what our customers have to say about their experiences with us. Join the thousands of satisfied
              travelers who have chosen us for their adventures.
            </p>
          </div>
          <div class="mx-auto w-full max-w-sm space-y-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="mx-auto h-32 w-32"
            >
              <path d="m3 21 1.9-5.7a8.5 8.5 0 1 1 3.8 3.8z"></path>
            </svg>
          </div>
        </div>
      </section>
      {/* <section class="w-full py-6 md:py-12 lg:py-24">
        <div class="container flex flex-col items-center justify-center space-y-4 px-4 md:px-6">
          <div class="space-y-2 text-center">
            <h2 class="text-3xl font-bold tracking-tighter lg:text-5xl">Ready to start your adventure?</h2>
          </div>
        </div>
      </section> */}
    </main>
  </div>
  )
}

export default Features