import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import Navigation from './components/Navigation';
import ContactPage from './components/ContactPage';
import About from './components/About';
import ScrollToTop from './components/ScrollToTop';
import ProductDetail from './components/ProductDetail'; // Import the ProductDetail component
import Features from './components/Features';
import Footer from './components/Footer';
import CancellationPolicy from './components/CancellationPolicy';
import './App.css'
import Gallery from './components/Gallery';

function App() {
 return (
    <Router>
      <Navigation />
      <ScrollToTop/>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/features" element={<Features/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/CancellationPolicy" element={<CancellationPolicy/>} />
        <Route path="/product/:name" element={<ProductDetail />} />
        <Route path='/gallery' element={<Gallery/>}/>
      </Routes>
      <Footer/>
    </Router>
 );
}

export default App;
