
import React from 'react';
import 'tailwindcss/tailwind.css';
import VinayF from '../assets/images/VinayF.jpeg'
import khushiSH from '../assets/images/KhushiSH.jpeg'
import deepakCoF from '../assets/images/DeepakCoF.jpeg'

const About = () => {
 return (
  <div className='w-full overflow-hidden'>
    <div className="bg-gray-50 py-6 lg:py-12">
      <div className="container px-4 md:px-6">
        <div className="grid items-center gap-6 lg:grid-cols-2 lg:gap-12">
          <div className="space-y-2">
            <h2 className="italianno-regular">Learn about us</h2>
            <h1 className='text-black text-3xl mb-4 font-bold leading-tight font-poppins'>Dare to Explore with HappilyTrip</h1>
            <p className="md:max-w-[600px] w-auto text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed font-poppins">
              We believe that every journey is an opportunity for adventure. HappilyTrip is dedicated to curating
              unforgettable travel experiences that immerse our customers in the beauty, culture, and excitement of the
              world. 
            </p>
          </div>
          <div className="grid items-center gap-4">
            {/* <img
              src="/placeholder.svg"
              alt="PlaceholderImage"
              // width="500"
              // height="280"
              className="aspect-video overflow-hidden rounded-xl object-cover object-center w-auto md:max-w-96"
            /> */}
            <img
              src="/HapplytripImage.jpg"
              alt="HapplytripImage"
              className="aspect-video overflow-hidden rounded-xl object-cover object-center w-auto md:max-w-96"
            />
          </div>
        </div>
      </div>
    </div>
    <section className="py-12 lg:py-24">
      <div className="container px-4 md:px-6">
        <div className="grid gap-6 lg:grid-cols-3 lg:gap-12">
          <div className="flex flex-col items-center space-x-4 border-2 rounded-2xl p-4">
            <img
              src={VinayF}
              width="100"
              height="100"
              alt="Image1"
              className="rounded-full object-cover object-center"
              style={{ aspectRatio: "100 / 100", objectFit: "cover" }}
            />
            <div className="space-y-2">
              <h3 className="text-2xl font-bold text-center font-poppins">Vinay</h3>
              <p className="text-center font-semibold text-[#e8604c] text-xl font-poppins">Founder</p>
              <p className="text-gray-500 font-poppins">
                Vinay, the founder of the company, is a visionary with a passion for travel. His love for exploring new places and cultures led him to establish HappilyTrip. His leadership and strategic direction have been instrumental in shaping the company’s success.
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center space-x-4 border-2 rounded-2xl p-4">
            <img
              src={deepakCoF}
              width="100"
              height="100"
              alt="Image2"
              className="rounded-full object-center object-scale-down"
              style={{ aspectRatio: "100 / 100", objectFit: "center" }}
            />
            <div className="space-y-2">
              <h3 className="text-2xl font-bold text-center font-poppins">Deepak</h3>
              <p className="text-center font-semibold text-[#e8604c] text-xl font-poppins">Co-Founder</p>
              <p className="text-gray-500 font-poppins">
                Deepak, the co-founder, brings a wealth of experience in the HappilyTrip. His keen eye for detail and commitment to providing the best user experience have been key in developing the HappilyTrip. Deepak’s collaborative approach and tarvel expertise make him an invaluable asset to the team.
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center space-x-4 border-2 rounded-2xl p-4">
            <img
              src={khushiSH}
              width="100"
              height="100"
              alt="Image3"
              className="rounded-full object-cover object-center"
              style={{ aspectRatio: "100 / 100", objectFit: "cover" }}
            />
            <div className="space-y-2">
              <h3 className="text-2xl font-bold text-center font-poppins">Khushi</h3>
              <p className="text-center font-semibold text-[#e8604c] text-xl font-poppins">Sales Head</p>
              <p className="text-gray-500 font-poppins">
                Khushi, the head of sales, is a dynamic and results-driven professional. Her exceptional communication skills and customer-centric approach have significantly boosted the company’s sales performance. Khushi’s dedication and drive have played a crucial role in expanding the company’s customer base and market reach.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="border-t border-b py-12 lg:py-24">
      <div className="container px-4 md:px-6">
        <div className="grid gap-6 lg:grid-cols-2 lg:gap-12">
          <div className="space-y-4">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl font-poppins">Our Values</h2>
            <p className="max-w-2xl text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed font-poppins">
              At HappilyTrip, we are committed to providing exceptional service and unforgettable experiences. Our
              values guide everything we do, from crafting the perfect itinerary to ensuring the comfort and
              satisfaction of our customers during their travels.
            </p>
          </div>
          <div className="grid gap-4 md:grid-cols-2 md:gap-4">
            <div className="flex items-start gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="h-4 w-4 flex-shrink-0"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
              <p className="text-sm text-gray-500 font-poppins">
                Customer satisfaction is our top priority. We go above and beyond to meet the needs and preferences of
                our travelers, creating personalized experiences that exceed expectations.
              </p>
            </div>
            <div className="flex items-start gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="h-4 w-4 flex-shrink-0"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
              <p className="text-sm text-gray-500 font-poppins">
                Professionalism and expertise are the cornerstones of our service. Our team of tour guides and travel
                experts are knowledgeable, friendly, and dedicated to ensuring a smooth and enjoyable journey for our
                customers.
              </p>
            </div>
            <div className="flex items-start gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="h-4 w-4 flex-shrink-0"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
              <p className="text-sm text-gray-500 font-poppins">
                Sustainability and responsible tourism are integral to our mission. We strive to minimize the
                environmental impact of our tours, support local communities, and promote cultural awareness and
                preservation.
              </p>
            </div>

            <div className="flex items-start gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="h-4 w-4 flex-shrink-0"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
              <p className="text-sm text-gray-500 font-poppins">
                We uphold a strict policy of
                respect towards female co-passengers. Any misbehavior will lead
                to the immediate removal of the entire group from the trip. Those
                responsible will have to arrange their own travel and
                accommodation at their own expense, and they will no longer be
                part of this trip. We will not be liable for any expenses incurred.
              </p>
            </div>
            <div className="flex items-start gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="h-4 w-4 flex-shrink-0"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
              <p className="text-sm text-gray-500 font-poppins">
                Full payment of the trip
                cost must be made upon boarding the traveler. Pending payments
                may result in trip cancellation without a refund of the booking
                amount.
              </p>
            </div>
            <div className="flex items-start gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="h-4 w-4 flex-shrink-0"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
              <p className="text-sm text-gray-500 font-poppins">
                Transport Breakdown: If you have a breakdown, please be
                patient. We appreciate your understanding as we work to repair
                the transport. Delays caused by breakdowns are beyond our
                control.
              </p>
            </div>
            <div className="flex items-start gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="h-4 w-4 flex-shrink-0"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
              <p className="text-sm text-gray-500 font-poppins">
                Seating arrangements for the traveler
                are at our discretion. We strive to accommodate couples together.
                Failure to comply with our seating requests may result in the
                cancellation of the trip for the individual without any refund.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="py-12 lg:py-24">
      <div className="container px-4 md:px-6">
        <div className="flex flex-col gap-4 lg:gap-8">
          <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl font-poppins">Ready to embark on an unforgettable journey?</h2>
          <p className="max-w-[600px] text-gray-500 md:text-xl/relaxed font-poppins">
            Contact us to learn more about our upcoming tours or to start planning your custom travel experience. Our
            team is here to make your travel dreams a reality.
          </p>
          
        </div>
      </div>
    </section>
  </div>
 );
};

export default About;


