
import React, { useState, useEffect } from 'react';
import 'tailwindcss/tailwind.css';

const ScrollToTop = () => {
 const [isVisible, setIsVisible] = useState(false);

 // Show button when page is scrolled upto given distance
 const toggleVisibility = () => {
   if (window.pageYOffset > 300) {
     setIsVisible(true);
   } else {
     setIsVisible(false);
   }
 };

 // Set the top cordinate to 0
 // make scrolling smooth
 const scrollToTop = () => {
   window.scrollTo({
     top: 0,
     behavior: "smooth"
   });
 };

 useEffect(() => {
   window.addEventListener("scroll", toggleVisibility);
   return () => window.removeEventListener("scroll", toggleVisibility);
 }, []);

 return (
   isVisible && (
     <div onClick={scrollToTop} className="fixed bottom-10 right-4 cursor-pointer z-40 mb-16">
       <button className="bg-[#e8604c] text-white font-bold p-4 rounded-full">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18" />
            </svg>
       </button>
     </div>
   )
 );
};

export default ScrollToTop;
