import React from 'react';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import DetailPage1 from '../assets/images/DetailPage1.jpeg'
import DetailPage5 from '../assets/images/DetailPage5.jpeg'
import DetailPage7 from '../assets/images/DetailPage7.jpeg'
import image10 from "../assets/images/image10.jpg"


const GalleryCarousel = ({ deviceType }) => { // Accept deviceType as a prop
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3,
          slidesToSlide: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          slidesToSlide: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1
        }
    };

    return (
        <Carousel
            responsive={responsive}
            ssr={true}
            // showDots={true}
            infinite={true}
            swipeable={true}
            autoPlay={deviceType !== "mobile"} // Adjusted to use deviceType prop
            autoPlaySpeed={2000}
            keyBoardControl={true}
            customTransition="all 1s" // Corrected typo and added 's' for seconds
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            deviceType={deviceType} // Pass deviceType prop to Carousel
        >
            
          <img
            alt=''
            src={DetailPage1}
            loading='lazy'
          />
          <img
            alt=''
            src={DetailPage5}
            loading='lazy'
          />
          <img
            alt=''
            src={image10}
            loading='lazy'
          />
          <img
            alt=''
            src={DetailPage7}
            loading='lazy'
          />
            
        </Carousel>
    );
}

export default GalleryCarousel;
