
import React, { useEffect } from 'react';
import ProductCard from './ProductCard';
import { gsap } from 'gsap';
import { Destination } from '../utils/constants';


const Destinations = () => {
 useEffect(() => {
    gsap.fromTo(".product-card", {
       x: "-100%", // Start from off-screen to the left
       opacity: 0,
    }, {
       duration: 1.5,
       x: "0%", // End at the original position
       opacity: 1,
       stagger: 0.2, // Add a stagger effect for a sequential slide-in
       ease: "power2.out",
       onComplete: () => {
         gsap.set(".product-card", { x: "0%", opacity: 1 }); // Ensure final state is set
       },
    });
 }, []);

 return (
    <section className="py-4 px-4 w-full overflow-hidden ">
      <div className="container mx-auto flex  flex-col items-center justify-center product-card">
        <h2 className="italianno-regular">Featured Tours</h2>
        <h1 className='text-black text-3xl mb-4 font-bold leading-tight font-poppins'>Most Popular Tours</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 w-auto lg:w-[90%] mx-auto space-y-4 justify-center items-center">
          {Destination.destinations.map((destination) => (
            <ProductCard
              key={destination.name}
              name={destination.name}
              description={destination.description}
              images={destination.images}
              highlights={destination.highlights}
              travelTips={destination.travelTips}
              location={destination.location}
              mapLink={destination.mapLink}
              duration={destination.duration}
              startingPrice={destination.startingPrice}
              totalTravelers={destination.totalTravelers}
              className="product-card"
            />
          ))}
        </div>
      </div>
    </section>
 );
};

export default Destinations;
