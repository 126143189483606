const IncludeExclude = ({ include, exclude }) => {
  console.log(include);
  return (
    <div className="w-full">
      <div className="p-4 md:p-16 w-auto md:w-full">
        <h3 className="text-2xl font-semibold text-black font-poppins">
          Included/Excluded
        </h3>
        <div className="flex flex-col md:flex-row gap-y-4 w-full">
          {Array.isArray(include) ? (
            include.map((include) => (
              <ul className="p-2 w-auto md:w-[50%] font-poppins">
                <li className="flex text-base text-gray-500 gap-x-1">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2.5"
                      stroke="#e8604c"
                      class="w-5 h-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m4.5 12.75 6 6 9-13.5"
                      />
                    </svg>
                  </span>
                  <p>{include}</p>
                </li>
              </ul>
            ))
          ) : (
            <ul className="p-2 w-auto md:w-[50%] font-poppins">
              <li className="flex text-base text-gray-500 gap-x-1">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2.5"
                    stroke="#e8604c"
                    class="w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m4.5 12.75 6 6 9-13.5"
                    />
                  </svg>
                </span>
                <p>
                  <span className="font-semibold">
                    Comfortable Transportation:
                  </span>{" "}
                  Relax in our air-conditioned traveler for a pleasant ride to
                  all your destinations.
                </p>
              </li>
              <li className="flex text-base text-gray-500 gap-x-1">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2.5"
                    stroke="#e8604c"
                    class="w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m4.5 12.75 6 6 9-13.5"
                    />
                  </svg>
                </span>
                <p>
                  <span className="font-semibold">Expert Guidance:</span>Enjoy
                  the insights of a dedicated tour guide, enriching your
                  experience at every stop.
                </p>
              </li>
              <li className="flex text-base text-gray-500 gap-x-1">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2.5"
                    stroke="#e8604c"
                    class="w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m4.5 12.75 6 6 9-13.5"
                    />
                  </svg>
                </span>
                <p>
                  <span className="font-semibold">
                    Luxurious Accommodation:
                  </span>
                  Unwind in a 3-star hotel in Manali for 2 nights, followed by a
                  night of camping in Swiss tents, complete with optional
                  washrooms.
                </p>
              </li>
              <li className="flex text-base text-gray-500 gap-x-1">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2.5"
                    stroke="#e8604c"
                    class="w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m4.5 12.75 6 6 9-13.5"
                    />
                  </svg>
                </span>
                <p>
                  <span className="font-semibold">Delicious Meals:</span> Treat
                  yourself to 3 breakfasts and 3 dinners, showcasing a variety
                  of mouthwatering dishes.
                </p>
              </li>
              <li className="flex text-base text-gray-500 gap-x-1">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2.5"
                    stroke="#e8604c"
                    class="w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m4.5 12.75 6 6 9-13.5"
                    />
                  </svg>
                </span>
                <p>
                  <span className="font-semibold">
                    Entertainment and Bonfire:
                  </span>
                  Dance to lively DJ nights and cozy up around the bonfire for
                  an unforgettable evening under the stars.
                </p>
              </li>
              <li className="flex text-base text-gray-500 gap-x-1">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2.5"
                    stroke="#e8604c"
                    class="w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m4.5 12.75 6 6 9-13.5"
                    />
                  </svg>
                </span>
                <p>
                  <span className="font-semibold">
                    Comprehensive Sightseeing:
                  </span>{" "}
                  Explore all the must-see attractions, including the
                  breathtaking Rohtang Pass.
                </p>
              </li>
              <li className="flex text-base text-gray-500 gap-x-1">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2.5"
                    stroke="#e8604c"
                    class="w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m4.5 12.75 6 6 9-13.5"
                    />
                  </svg>
                </span>
                <p>
                  <span className="font-semibold">Hassle-free Travel:</span>
                  Leave the logistics to us – all tolls, parking charges, GST,
                  and fees are covered, ensuring a transparent and stress-free
                  journey.
                </p>
              </li>
            </ul>
          )}

          {Array.isArray(exclude) ? (
            exclude.map((exclude, index) => (
                <ul className="p-2 w-auto md:w-[50%] font-poppins">
                <li className="flex text-base text-gray-500 gap-x-1">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2.5"
                    stroke="currentColor"
                    class="w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                </span>
                <p>{exclude}</p>
              </li>
                </ul>
            ))
          ) : (
            <ul className="p-2 w-auto md:w-[50%] font-poppins">
              <li className="flex text-base text-gray-500 gap-x-1">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2.5"
                    stroke="currentColor"
                    class="w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                </span>
                <p>
                  <span className="font-semibold">
                    Lunch and Adventure Activities:
                  </span>
                  Please note that the package does not cover lunch expenses or
                  adventurous activities such as Paragliding, Rafting, and Snow
                  Skiing, offering you the freedom to choose and plan these
                  activities independently.
                </p>
              </li>
              <li className="flex text-base text-gray-500 gap-x-1">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2.5"
                    stroke="currentColor"
                    class="w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                </span>
                <p>
                  <span className="font-semibold">Hotel Heater Charges:</span>
                  Charges for room heaters in the Manali hotel are not included
                  and will be an additional expense if used.
                </p>
              </li>
              <li className="flex text-base text-gray-500 gap-x-1">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2.5"
                    stroke="currentColor"
                    class="w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                </span>
                <p>
                  <span className="font-semibold">
                    Riverside Camping Availability:
                  </span>
                  Riverside camping is subject to availability and might not
                  always be guaranteed.
                </p>
              </li>
              <li className="flex text-base text-gray-500 gap-x-1">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2.5"
                    stroke="currentColor"
                    class="w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                </span>
                <p>
                  <span className="font-semibold">
                    Entry Fees and Unforeseen Costs:
                  </span>
                  Entrance fees to monuments and any additional costs arising
                  from natural calamities like roadblocks or landslides are not
                  included. The package also does not cover insurance expenses
                  or conditional expenses due to weather conditions or political
                  or technical issues.
                </p>
              </li>
              <li className="flex text-base text-gray-500 gap-x-1">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2.5"
                    stroke="currentColor"
                    class="w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                </span>
                <p>
                  <span className="font-semibold">Personal Belongings:</span>We
                  are not liable for items of personal nature. Please take care
                  of your belongings, and any loss or theft is your
                  responsibility.
                </p>
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default IncludeExclude;
