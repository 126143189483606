import React, { useState } from 'react';
import OpenWhatsAppChat from './OpenWhatsAppChat';

const ContactPage = () => {
 const [formState, setFormState] = useState({
    name: '',
    email: '',
    message: '',
 });

 const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.id]: e.target.value,
    });
 };

 const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send the form data to your backend or a third-party service
    console.log(formState);

    // Simulate sending an email
    window.location.href = `mailto:contact@happilytrip.com?subject=Contact Form Submission&body=${encodeURIComponent(formState.message)}`;

    // Simulate initiating a call
    window.location.href = `tel:+919034943903`;

    // Reset the form state to clear the form
    setFormState({
        name: '',
        email: '',
        message: '',
    });
 };

 return (
    <div className="flex flex-col min-h-screen">
      <div className="container mx-auto px-4 py-8">
        <h2 className="text-3xl font-bold mb-6 font-poppins">Contact Us</h2>
        <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2 font-poppins" htmlFor="name">
              Name
            </label>
            <input
              className="shadow font-poppins appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="name"
              type="text"
              placeholder="Your Name"
              value={formState.name}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <label className="block font-poppins text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Email
            </label>
            <input
              className="shadow font-poppins appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="email"
              placeholder="Your Email"
              value={formState.email}
              onChange={handleChange}
            />
          </div>
          <div className="mb-6">
            <label className="block font-poppins text-gray-700 text-sm font-bold mb-2" htmlFor="message">
              Message
            </label>
            <textarea
              className="shadow font-poppins appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              id="message"
              placeholder="Your Message"
              value={formState.message}
              onChange={handleChange}
            ></textarea>
          </div>
          <div className="flex items-center justify-between">
            <button className="bg-emerald-400/90 font-poppins text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
              Send
            </button>
          </div>
        </form>
        <div className="container mx-auto px-4 py-8">
          <h2 className="text-3xl font-bold mb-6 font-poppins">Contact Information</h2>
          <p className="mb-4 p-2 px-4 hover:bg-black/10 bg-black/5 rounded-full items-center font-poppins"><span className='font-medium text-lg'>Email:</span> contact@happilytrip.com</p>
          <p className="mb-4 p-2 px-4 hover:bg-black/10 bg-black/5 rounded-full items-center font-poppins"><span className='font-medium text-lg'>Phone:</span> +919034943903</p>
          <div className='flex items-center space-x-4 ml-4'>
            <a href="tel:+919034943903">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='w-6 h-6'>
                  <path fill="#000000" d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/>
              </svg>
            </a>
            <OpenWhatsAppChat/>
          </div>
          <h2 className="text-3xl font-bold mb-6 mt-8 font-poppins">Follow Us</h2>
          <div className="flex space-x-4">
            <a href="https://www.instagram.com/happilytrip.in/?igsh=MWxyNWRnNHRkdjRpMA%3D%3D" className="w-8 h-8">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#fb0364" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>
            </a>
            {/* <Link to={'/'} className="text-blue-500 hover:text-blue-700">Twitter</Link> */}
            {/* <Link to={'/'} className="text-blue-500 hover:text-blue-700">Instagram</Link> */}
          </div>
        </div>
      </div>
    </div>
 );
};

export default ContactPage;
